<template>
    <div class="msp-tabella-record">
        <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            class="elevation-1"
            :mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
            @click:row="onClickRow"
        >
            <template v-slot:item.target="{ item }">
                <div class="target">{{item.stringTarget}}</div>
</template>
            <template v-slot:item.dataRecord="{ item }">
                <div class="data">{{formatDate(item.dataRecord)}}</div>
</template>
<template v-slot:item.risultato="{ item }">
    <div class="result">{{item.stringRisultato}}</div>
</template>
</v-data-table>
    </div>
</template>
<script type="module">
import _calendario from "@src/js/vue/mixins/calendario.js";

const component = {
    props: {
        data: {
            type: Array,
            default: ()=>[]
        },
        headers: {
            type: Array,
            default: ()=>[]
        },
    },
    mixins: [
    ],
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
        formatDate: _calendario.ymd2dateShort,
        onClickRow: function (item) {
            this.$emit("select", item);
        },
    }
};
export default component;
</script>

<style lang="scss">
.msp-tabella-record {
    th {
        vertical-align: top;
        padding: 5px 16px !important;
    }
    td {
        white-space: nowrap !important;
    }
    .data {
        font-size: 0.8em;
    }
    .result {
        font-weight: bold;
    }
}
</style>
