<template>
    <div class="record-segmenti" >
        <msp-app-bar title="Migliori prestazioni"></msp-app-bar>
        <v-card v-if="isLoading">
            <v-card-text>
                <p>
                    Carico i dati...
                </p>
                <v-progress-linear
                    indeterminate
                    color="green darken-2"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
            <v-card v-if='needAnalisiRecord'>

                <v-card-text>
                    <p>Qui potrai vedere i tuoi migliori risultati.</p>
                    <p>Prima di tutto dobbiamo elaborare i tuoi allenamenti degli ultimi {{mesi}} mesi presenti su CoachPeaking.</p>
                    <p>Questa elaborazione verrà eseguita una sola volta e richiederà circa {{mesi*10}} secondi.</p>
                    <p>Nei prossimi allenamenti questi calcoli verranno fatti in automatico senza nessun intervento da parte tua.</p>
                    <p class="buttons">
                            <v-btn v-if="!isLoading" @click="analisiRecord" color="primary">Elabora ({{mesi}} mesi)</v-btn>
                        </p>

                        </v-card-text>

                        </v-card>
                        

                        <div v-if='!isLoading && !needAnalisiRecord && !dialogRecupero'>
                            <v-card>
                                <v-card-text>
                                    <v-select
                                        :items="filteredSports"
                                        item-text="label"
                                        v-model="sport"
                                        @change="changeSport"
                                        label="Sport"
                                    ></v-select>

                <v-select
                    :items="listaPeriodi"
                    item-text="text"
                    item-value="value"
                    v-model="periodo"
                    @change="changePeriodo"
                    label="Scegli un periodo"
                ></v-select>
                                </v-card-text>
                            </v-card>
                            <msp-tabella-record 
                                :data="records"
                                :headers="headers"
                                @select="onSelectRow"
                            ></msp-tabella-record>
                            <v-btn v-if="records && records.length" @click="openTutti" class="btn-analisi" color="primary">Analisi dei record</v-btn>
                            <v-btn v-if="!dialogRecupero" @click="resetLastUpdate" class="btn-reset" small color="secondary">Ripeti elaborazione {{mesi}} mesi</v-btn>
                            
                        </div>
<v-card v-if='dialogRecupero && !isLoading && !needAnalisiRecord'>

                                <v-card-text>
                                    <p>Forza l'elaborazione degli allenamenti degli ultimi {{mesi}} mesi.</p>
                                    <p>Questa elaborazione richiederà circa {{mesi*10}} secondi.</p>
                                    <p class="buttons">
                                        <v-btn  @click="closeDialogoRecupero" color="gray">
                                            <v-icon>mdi-chevron-left</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="!isLoading" @click="analisiRecord" color="primary">Elabora ({{mesi}} mesi)</v-btn>
                                    </p>

                                </v-card-text>

                            </v-card>
                <msp-app-footer></msp-app-footer>
        </div>
</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import mspAppFooter from "@components/MspAppFooter.vue";
import MspTabellaRecord from "@components/MspTabellaRecord.vue";
import MspBestRecord from "@components/MspBestRecord.vue";
import _record from "@mixins/record-segmenti.js"
import { mapGetters } from 'vuex'
import _funzioni from "@src/js/msp/funzioni.js"
import {env} from "@mixins/environment.js"
import api, {URLparams} from "@mixins/api.js";
const endpoint = env.api_base_url+"/api/v2/record.php";
_record.aggiungiAnniListaPeriodi(_record.listaPeriodi, 1);

const component = {
    mixins: [
    ],
    components: {
        mspAppBar,
        mspAppFooter,
        MspTabellaRecord,
        MspBestRecord,
    },
    data() {
        return {
            refreshTabella: 0,
            isLoading: true,
            mesi: 6,
            dialogRecupero: true,
            availableSports: [],
            sports: _record.sports,
            tipoRecord: null,
            record: "",
            periodo: "ALL",
            sport:null,
            listaRecord: [ ],
            listaPeriodi: _record.listaPeriodi,
            records: [],
            headers: _record.headers,
        }
    },
    computed: {
        ...mapGetters('atleti', [
            "atleta",
            "atletaId",
        ]),
        ...mapGetters('utente', [
            "options",
        ]),
        ...mapGetters('app', [
            "headerHeight",
        ]),
        filteredSports: function () {
            return _record.sports.filter(el=>this.availableSports.includes(el.sport_id));
        },
        styleSticky: function () {
            if (this.headerHeight) {
                return `--top: ${this.headerHeight}px`;
            }
            return "";
        },
        needAnalisiRecord: function () {
            return !this.isLoading && !(this.availableSports && this.availableSports.length);
        },
    },
    watch: {
        atletaId: function () {
            this.loadTabella();
        },
        tipoRecord: function () {
            this.loadTabella();
        },
        periodo: function () {
            this.loadTabella();
        },
    },
    mounted: function () {
        this.init();
    },
    methods: {
        onSelectRow: function (item) {
            this.openTarget(item.target, this.sport);
        },
        closeDialogoRecupero: function () {
            this.dialogRecupero = false;
        },
        openDialogoRecupero: function () {
            this.dialogRecupero = true;
        },
        resetTabella: function () {
            this.records = [];
            this.firstRecord = false;
        },
        formatRisultato: _record.formatRisultato,
        makeRecord: function (data) {
            return data.map((el)=>{
                const formatted = {
                    ...el,
                };
                const extra = this.formatRisultato(el, el.target);
                for(let key in extra){
                    const ex = extra[key];
                    if (ex.um) {
                    formatted[key] = `${ex.value} ${ex.um}`;
                    } else {
                    formatted[key] = `${ex.value}`;
                    }
                }
                return formatted;
            });
        },
        makeTabella: function (data) {
            if (!(data && data.length)) {
                this.resetTabella();
                return;
            }
            this.records = this.makeRecord(data);
            this.closeDialogoRecupero();

        },
        analisiRecord: function () {
            if (!this.atletaId) return;
            let data = {
                recupera_attivita: 1,
                atleta_id: this.atletaId,
            }
            let params = URLparams(data);
            let url = `${endpoint}?${params}`;

            this.isLoading = true;
            this.tipoRecord = null;
            return new Promise((resolve,reject)=>{
                api(url,'post',null)
                    .then(
                        (result)=>{
                            this.isLoading = false;

                            if (result.success) {
                                this.closeDialogoRecupero();
                                this.updateTabella();
                                this.updateUltimaElaborazione();
                                resolve(true);
                            } else {
                                this.isLoading = false;
                                this.closeDialogoRecupero();
                                resolve(null);
                            }
                        },
                        (error)=>{
                            this.isLoading = false;
                            reject(error);
                        }
                    )
            });
        },
        resetLastUpdate: function () {
            this.openDialogoRecupero();
        },
        updateUltimaElaborazione: function () {
            if (!this.atletaId) return;
            let data = {
                last_update: 1,
                atleta_id: this.atletaId,
            }
            let params = URLparams(data);
            let url = `${endpoint}?${params}`;
            return new Promise((resolve,reject)=>{
                api(url,'post',null)
                    .then(
                        (result)=>{
                            if (result.success) {
                                resolve(true);
                            } else {
                                resolve(null);
                            }
                        },
                        (error)=>{
                            reject(error);
                        }
                    )
            });
        },

        getUltimaElaborazione: function () {
            if (!this.atletaId) return;
            let data = {
                last_update: 1,
                atleta_id: this.atletaId,
            }
            let params = URLparams(data);
            let url = `${endpoint}?${params}`;
            return new Promise((resolve,reject)=>{
                api(url,'get',null)
                    .then(
                        (result)=>{
                            if (result.success) {
                                resolve(result.data);
                            } else {
                                resolve(null);
                            }
                        },
                        (error)=>{
                            reject(error);
                        }
                    )
            });
        },

        updateTabella: function () {
            this.refreshTabella = (new Date()).valueOf();
            this.updateSports();
        },
        openTutti: function () {
            this.$router.push("/record/analisi");
        },
        openTarget: function (target, sport) {
            const query = { 
                target: target,
                sport: sport,
            };
            this.$router.push({path: "/record/analisi", query: query});
        },
        renderOptionRecord: function (option) {
            if (!option) return "";
            let render = "";
            if (this.sport == 'PODISMO') {
                render = `${option.valore} ${option.umSegmento}`;
            } else { // CICLISMO
                render = _funzioni.sec2hms(option.valore);
            }
            return render;
        },
        init: function () {
            this.checkElaborazione();
            this.updateSports();
         },
         updateSports: async function () {
            await this.loadAvailableSports();
             this.setSportDefault()
         },
         checkElaborazione: async function () {
             const elaborata = await this.getUltimaElaborazione();
             if (!elaborata) {
                 this.closeDialogoRecupero();
             }

         },
         getOptionSportId: async function () {
             let record_sport = await this.$store.dispatch("utente/getOption", 'record-sport');
             return record_sport;
         },
        loadAvailableSports: async function () {
            const records = await this.loadCountRecord();
            if (!records) return null;
            if (!records.length) return null;
            let sportIds = records.map(el=>el.sport_id);
            this.availableSports = sportIds;
        },

        getDefaultSportID: async function () {
            let sport_id;
            if (!this.availableSports.length == 1) {
                sport_id = this.availableSports[0];
            } else {
                const preference = [2,1];
                let selectPreference = preference.find(pre=>this.availableSports.includes(pre));
                if (selectPreference) {
                    sport_id = selectPreference;
                } else {
                    sport_id = null;
                }
            }
            return sport_id;
        },
        setSportDefault: async function () {
            let sport_id = await this.getOptionSportId();
            if (!sport_id) {
                sport_id = await this.getDefaultSportID();
            }
            if (!sport_id) return;
            const sport = _record.getSportById(sport_id);
            if (!sport) return;
            this.sport = sport.value;
            this.changeSport();
            
        },
        loadCountRecord: function () {
            if (!this.atletaId) return;
            return _record.loadCountRecord(this.atletaId);
        },
        dismissModal () {
            this.$store.dispatch("calendario/setDialog", false);
            this.$router.go(-1);
        },
        loadTabella: function () {
            if (!(this.atletaId && this.tipoRecord)) return;
            let data = {
                tabella: 1,
                atleta_id: this.atletaId,
                tipo_record: this.tipoRecord,
                periodo: this.periodo,
            }
            let params = URLparams(data);
            let url = env.api_base_url+`/api/v2/record.php?${params}`;
            this.resetTabella();
            this.isLoading = true;

            return new Promise((resolve,reject)=>{
                api(url,'get')
                    .then(
                        (result)=>{

                            this.isLoading = false;

                            if (result.success) {
                                this.makeTabella(result.data);
                                resolve(result);
                            } else {
                                resolve(null);
                            }
                        },
                        (error)=>{
                            this.isLoading = false;
                            reject(error);
                        }
                    )
            });
        },
        changePeriodo: function () { },
        changeSport: function () {
            const sport = _record.getSport(this.sport);
            this.tipoRecord = sport.tipo_record;
            this.headers = _record.headersByTipoTabellaRecord('best',this.tipoRecord);
            this.$store.dispatch("utente/setOption", {key:'record-sport', value:sport.sport_id});
        },
    }
};
export default component;
</script>

<style lang="scss">
.record-segmenti {
    .striped tr:nth-child(odd)  td {
        background: var(--col-grigio-chiarissimo);
    }
    .v-btn.btn-analisi,
    .v-btn.btn-reset {
        display: flex;
        margin: 10px auto !important;
    }
    .buttons {
        display: flex;
    }
}

</style>
