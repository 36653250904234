<template>
    <div class="msp-grafico-record">
        <div class="grafico"></div>
    </div>
</template>
<script type="module">
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import _calendario from "@src/js/vue/mixins/calendario.js"
import {mergeDeep} from "@mixins/merge.js"
am4core.options.autoDispose = true;



const chartReg = {};
const addChart = function addChart(id, fn_chart, args) {
    maybeDisposeChart(id);
    chartReg[id] = fn_chart(...args);
}

const maybeDisposeChart = function maybeDisposeChart(id) {
    if (chartReg[id]) {
        chartReg[id].dispose();
        delete chartReg[id];
    }
}

const disegnaGraficoBullet = function (target, arrayDati, options = {}) {
    const defaults = {
        valueAxisType: 'duration',
        valueAxisRenderInversed: true,
    }
    const settings = mergeDeep(defaults,options);


    if (Array.isArray(arrayDati) && arrayDati.length > 0) {  
        var datiTrasformati = arrayDati.map(function(elemento) {
            return {
                dataRecord: elemento.dataRecord,
                risultato: parseFloat(elemento.risultato)
            };
        });
    } else {
        console.log("non è un array valido");
        return;
    }


    var chart = am4core.create(target, am4charts.XYChart);
    chart.data = datiTrasformati;
    chart.language.locale = am4lang_it_IT;
    chart.dateFormatter.utc = true;

    chart.events.on("datavalidated", function(ev) {
        let chart = ev.target;
        let adjustHeight = 300;
        let targetHeight = adjustHeight;//chart.pixelHeight + adjustHeight;
        chart.svgContainer.htmlElement.style.height = targetHeight + "px";
    });


    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.dateFormatter.firstDayOfWeek = 0;


    var dateAxis = chart.xAxes.push(new am4charts.ValueAxis());
    dateAxis.extraMax = 0.05;
    dateAxis.extraMin = 0.05;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.dataFields.category = "index";
    dateAxis.renderer.minGridDistance = 100;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.grid.template.strokeDasharray = "1,3";
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.renderer.labels.template.disabled = true;


    let valueAxis;
    if (settings.valueAxisType === 'duration') {
        const max = Math.max(...arrayDati.map(el=>el.risultato));
        const durationFormat = (max < 3600) ? "mm:ss" : "hh:mm:ss";
        settings.seriesTooltipText = "{dateX.formatDate('dd MMM yyyy')}\n{valueY.formatDuration('"+durationFormat+"')}";
        chart.durationFormatter.durationFormat = durationFormat;
        valueAxis = chart.yAxes.push(new am4charts.DurationAxis());
    } else {
        settings.seriesTooltipText = "{dateX.formatDate('dd MMM yyyy')}\n{valueY} watt";
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    }

    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.inversed = settings.valueAxisRenderInversed;
    valueAxis.renderer.labels.template.fontSize = "11px";


    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "index";
    series.dataFields.dateX = "dataRecord";
    series.dataFields.valueY = "risultato";

    series.strokeOpacity = 0;

    const bullet = series.bullets.create(am4charts.CircleBullet);
    bullet.tooltipText = settings.seriesTooltipText;
    bullet.hoverOnFocus = true;

    
    if ("top10" in settings) {
        settings.top10.forEach((best)=>{

            bullet.adapter.add("fill", function(fill, target) {

                if (target.dataItem.valueY == best.risultato
                    && (best.dataRecord == _calendario.date2ymd(target.dataItem.dateX))
                   ) { 
                       return am4core.color("#000000"); 
                   }
                   return fill; 
            }, 1);

        });

    }

    if ("best" in settings) {
        settings.best.forEach((best)=>{

            bullet.adapter.add("fill", function(fill, target) {

                if (target.dataItem.valueY == best.value
                    && (best.date == _calendario.date2ymd(target.dataItem.dateX))
                   ) { 
                       return am4core.color(best.color); 
                   }
                   return fill; 
            }, 20);



            bullet.adapter.add("scale", function(scale, target) {

                if (target.dataItem 
                    && (target.dataItem.valueY == best.value)
                    && (best.date == _calendario.date2ymd(target.dataItem.dateX))
                   ) {
                       return best.size; 
                   }
                   return scale; 
            });
        }, 20);

    }


    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.disabled = true;

    // Indici per la distribuzione omogenea sull'asse X
    chart.events.on("beforedatavalidated", function() {
        chart.data.forEach(function(dataItem, index) {
            dataItem.index = index + 1;
        });
    });

    return chart;

}


const disegnaGraficoRecordCP = function (target, listaDati, options) {
    const defaults = {
        valueAxisType: 'value',
        valueAxisRenderInversed: false,
    }
    const settings = mergeDeep(defaults,options);

    return disegnaGraficoBullet(target, listaDati, settings);
}

const disegnaGraficoRecordDistanza = function (target, listaDati, options) {
    const defaults = {
        valueAxisRenderInversed: true,
    }
    const settings = mergeDeep(defaults,options);
    return disegnaGraficoBullet(target, listaDati, settings);
} 

const funzioni = {
    "distanza": disegnaGraficoRecordDistanza,
    "cp-ciclismo": disegnaGraficoRecordCP,

};

const component = {
    props: {
        data: {
            type: Array
        },
        options: {
            type: Object,
            default: {},
        },
    },
    mixins: [
    ],
    components: {
    },
    data() {
        return {
            chart: null,
        }
    },
    computed: {
    },
    mounted() {
        this.renderChart();
    },
    watch: {
        data: {
            handler() {
                this.renderChart();
            },
            deep: true
        },
    },
    methods: {

        renderChart() {
            const target = this.$el.querySelector(".grafico");
            if (this.data && this.data.length) {
                const quale = this.data[0].mneSegmento;
                addChart('record', funzioni[quale], [target,this.data,this.options] );
            } else {
                maybeDisposeChart('record');
            }
        },

        unmounted: function () {
            maybeDisposeChart('record');
        }
    }
};
export default component;
</script>

<style lang="scss">
    .msp-grafico-record {
        width: 100%;
    }
</style>
